import { client } from "../../../api/client"
import { getLocalStorage } from '../../../hooks/appVariables'

export const fetchPermissionChange = async () => {
    if (process.env.REACT_APP_USELOCALDATA === "true") { 
        return require('./_local/_hasPermissionChangeData.json')
    } else {
        const user = getLocalStorage(process.env.REACT_APP_USER_KEY)
        const responseUsers = await client.get(`users/users/permissionchange/${user.emailAddress}`)
        return responseUsers.data
    }
};