import { useEffect, useState } from "react"
import { useQuery } from "react-query"

import { getLocalStorage, setLocalStorage } from '../hooks/appVariables'
import { getRedirectUrl } from '../utils/getRedirectUrl'
import { fetchCompanies } from '../features/companies/data/companyData'
import useStore from '../store'

const Impersonation = ({ children }) => { 
  const currentUser = getLocalStorage(process.env.REACT_APP_USER_KEY)
  const user = useStore((state) => state.user)
  const [impersonation, setImpersonation] = useState(false)
  
  const { data: companies } = useQuery(['companies', currentUser.id], () => fetchCompanies(), {refetchOnWindowFocus: false})

  useEffect(() => {
    if (getLocalStorage(process.env.REACT_APP_IMPERSONATE_KEY) && user && currentUser && companies) {
      setImpersonation(user.id !== currentUser.id)      
      const companyDefault = companies.find(c => c.isDefault === true)
      setLocalStorage(process.env.REACT_APP_COMPANY_KEY, companyDefault)
    }
  }, [user, currentUser, companies])

  const handleStopImpersonationClick = async (e) => {
      e.preventDefault()
      // Swap back to the original user
      setLocalStorage(process.env.REACT_APP_USER_KEY, user)
      // set the local storage company to an id of 0 so when the page is refreshed, the default for the user is set
      const companyDefault = {id:0,name:""}
      setLocalStorage(process.env.REACT_APP_COMPANY_KEY, companyDefault)
      // Switch off the impersonation flags
      setLocalStorage(process.env.REACT_APP_IMPERSONATE_KEY, false)
      setImpersonation(false)
      // Refresh the page
      window.location.href = getRedirectUrl()
  }
  return (
    <div className={impersonation ? "impersonation-active" :  "impersonation-passive"}>
        {impersonation ? (
        <div className="impersonation-surround">
          Impersonating: {currentUser?.firstName} {currentUser?.lastName} &nbsp;&nbsp;
          <i className="bi bi-x-circle-fill danger impersonation-button" title="Stop Impersonation" onClick={handleStopImpersonationClick}></i>
        </div>
        ) : (<></>) }
        {children}
    </div>
  );
};

export default Impersonation;
